@import "src/assets/styles/common";

.app-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  width: 100%;
  height: $header-height;
  background: $header-background-color;
  padding: 17px 104px;
  background: #FFFFFF;
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.4);

  .app-header__branding_menu-wrapper {
    display: flex;
    align-items: center;

    .app-header__branding-wrapper {

    }

    .app-header__menu-wrapper {
      margin-left: 16px;
      display: none;
    }

    @include md() {
      .app-header__menu-wrapper {
        display: block;
      }
    }
  }

  .app-header__utils_and_profile-wrapper {
    display: flex;
    align-items: center;

    .app-header__utils-wrapper {
      display: flex;
      align-items: center;

      .app-header__util {
        cursor: pointer;
        margin-right: 10px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .app-header__profile-wrapper {
      margin-left: 16px;
      display: flex;
      align-items: center;

      .app-header__profile-avatar {
        padding: 12px;
        width: 40px;
        height: 40px;
        background: #F9FAFB;
        border-radius: 100px;
        font-weight: 500;
        font-size: 14px;
        line-height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: #2AB7A3;
      }

      .app-header__profile-name {
        margin: 0 4px 0 10px;
        font-weight: 400;
        font-size: 16px;
        line-height: 32px;
        color: #232323;
      }

      .app-header__profile-arrow {

      }
    }
  }
}
