@import "src/assets/styles/common";

.card-component {
  background: #ffffff;
  border: 1px solid #EBEBEB;
  border-radius: 8px;
  margin-bottom: 24px;
  font-family: $font-family;

  &.shadow {
    border: none;
    box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  }

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &.showHeaderBgColor {
      background: #EEF4F9;
      padding-bottom: 20px;
    }

    .card-title-description-wrapper {
      padding: 20px 20px 0 20px;

      .card-title {
        font-weight: 500;
        font-size: 18px;
        color: #07213C;
      }

      .card-description {
        font-weight: 400;
        font-size: 14px;
        color: #83909D;
        margin-top: 5px;
      }
    }

    .card-actions {
      padding: 20px;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .card-body {
    padding: 20px;
  }
}
