@import "src/assets/styles/common";

// variables
$menu-active-link-bg-color: #2AB7A3;
$menu-active-link-color: #ffffff;
$menu-active-font-weight: 700;

.menu-list {

  display: flex;
  flex-direction: row;

  .menu-item-wrapper {
    position: relative;

    .sub-menu-list {
      display: flex;
      flex-direction: column;
      position: absolute;
      top: calc(100%);
      left: 0;
      width: 200px;
      background: #FFFFFF;
      border: 1px solid #FFFFFF;
      box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
      border-radius: 8px;
      padding-top: 10px;

      .sub-menu-item {
        padding: 8px 12px;
        border-radius: 4px;
        display: flex;
        align-items: center;
        margin-right: 4px;

        .sub-menu-item-title {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
        }


        &:hover {
          background-color: $menu-active-link-bg-color;

          .sub-menu-item-title {
            color: $menu-active-link-color;
          }
        }

        &.active {
          background-color: $menu-active-link-bg-color;

          .sub-menu-item-title {
            color: $menu-active-link-color;
            font-weight: $menu-active-font-weight;
          }
        }
      }
    }
  }

  .menu-item {
    padding: 8px 12px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-right: 4px;

    .menu-item-icon {
      color: inherit;
    }

    .menu-item-title {
      margin-left: 12px;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    .sub-menu-item-icon {
      color: inherit;
      margin-left: 4px;
    }

    &:hover {
      background-color: $menu-active-link-bg-color;

      .menu-item-icon, .menu-item-title, .sub-menu-item-icon {
        color: $menu-active-link-color;
      }
    }

    &.active {
      background-color: $menu-active-link-bg-color;

      .menu-item-icon, .menu-item-title, .sub-menu-item-icon {
        color: $menu-active-link-color;
        font-weight: $menu-active-font-weight;
      }
    }
  }
}
