@import "animations";
@import "variables";
@import "fonts";

@import "helpers";
@import "mixins";
@import "responsive-mixins";
@import "responsive-display";
@import "normalize";

// components
@import "components/grid";

.app-layout {

  .header-container {
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .content-container {
    padding: 30px 104px;
  }
}

.app-version {
  font-size: 10px;
  position: fixed;
  bottom: 5px;
  right: 5px;
}

.location-renderer {
    display: flex;
    align-items: center;
}

.vehicle-status-chip {
  &.tag-component {
    &.vehicleidle {
      @include tag-style(#FFFAEB, #FFCB05);
    }

    &.vehiclemoving {
      @include tag-style(#ECFDF3, #1BA719);
    }

    &.vehicleignitionoff {
      @include tag-style(#FEF3F2, #E53742);
    }

    &.devicenogprs {
      @include tag-style(#F2F4F7, #232323);
    }
  }
}
