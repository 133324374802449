.dashboard-stat-widget {
  background: #FFFFFF;
  border: 1px solid #EAECF0;
  box-shadow: 0 1px 3px rgba(16, 24, 40, 0.1), 0 1px 2px rgba(16, 24, 40, 0.06);
  border-radius: 8px;
  //min-width: 296px;
  padding: 24px;
  flex: 1;

  .widget__title {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #232323;
  }

  .widget__value-wrapper {
    margin: 4px 0 16px;
    display: flex;
    align-items: baseline;

    .widget__value {
      font-weight: 700;
      font-size: 32px;
      line-height: 44px;
      letter-spacing: -0.02em;
      color: #232323;
    }

    .widget__units {
      color: #656362;
      margin-left: 8px;
      font-weight: 500;
    }
  }

  .widget__timestamp {
    color: #656362;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }
}
