@import "src/assets/styles/common";

.live-vehicle-details-screen {

  .live-vehicle-details__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;

    .live-vehicle-details__left {
      display: flex;
      align-items: center;

      .live-vehicle-details__navigate-back {
        height: 40px;
        aspect-ratio: 1;
        margin-right: 12px;
        background: #FFFFFF;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .live-vehicle-details__reg-num {
        text-transform: uppercase;
        font-weight: 500;
        font-size: 22px;
        line-height: 26px;
        color: #000000;
      }

      .live-vehicle-details__status {
        margin: 0 16px 0 8px;

      }

      .live-vehicle-details__view-location {
        color: $primary-color;
      }
    }

    .live-vehicle-details__right {
      display: flex;
      align-items: center;

      .live-vehicle-details__last-updated-at {

      }

      .live-vehicle-details__refresh {
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .live-vehicle-details__body {
    .live-vehicle-details-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 400px;
      max-height: 900px;
      height: calc(100vh - 180px);
    }

    .live-vehicle-stats-wrapper {
      margin-bottom: 16px;
    }
  }
}
