.live-vehicle-list-screen {

  .live-vehicle-list-card {
    margin-bottom: 0;
    overflow: hidden;

    .card-body {
      padding: 0;
    }

    .list-screen__card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px 32px;

      .input-component, .input-field{
        margin-bottom: 0 !important;
      }

      .list-screen__card-header__left {

      }

      .list-screen__card-header__right {

      }
    }
  }

  .live-vehicle-list-table-container {
    .t-table-wrapper {
      min-height: 400px;
      max-height: calc(100vh - 210px);
      height: calc(100vh - 210px);
    }
  }
}
