.login-screen {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;

  .login-bg-wrapper {
    width: 827px;
    height: 100vh;
    background-image: url("./../../assets/images/login-bg.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    position: relative;

    .login-branding-logo {
      position: absolute;
      right: 8px;
      top: 8px;
    }

    .login-branding-meta {
      position: absolute;
      bottom: 80px;
      left: 40px;
      color: #FFFFFF;

      .login-branding-welcome {
        font-weight: 700;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 8px;
      }

      .login-branding-quote {
        font-weight: 400;
        font-size: 44px;
        line-height: 48px;
        width: 548px;
      }
    }
  }


  .login-form-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1 1;

    .login-form {
      width: 360px;

      .login-form-title {
        font-weight: 600;
        font-size: 30px;
        line-height: 38px;
        text-align: center;
        color: #232323;
        margin-bottom: 12px;
      }

      .login-form-sub-title {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #667085;
        margin-bottom: 12px;
      }

      .login-form-separator {
        margin: 20px 0 10px;
        width: 100%;
        display: flex;
        align-items: baseline;

        .login-form-separator__text {
          font-size: 14px;
          line-height: 20px;
          text-align: center;
          color: #667085;
          position: relative;
          top: 4px;
          background: inherit;
          padding: 0 16px;
        }

        &:before {
          content: "";
          display: block;
          border-top: 1px solid #D0D5DD;
          width: 100%;
          margin-bottom: 20px;
        }

        &:after {
          content: "";
          display: block;
          border-top: 1px solid #D0D5DD;
          width: 100%;
          margin-top: 20px;
        }
      }

      .login-form-social-btn {
        height: 44px;
        width: 100%;
        background: #FFFFFF;
        border: 1px solid #D0D5DD;
        box-shadow: 0 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .login-form-social-btn__icon {
          margin-right: 12px;
        }

        .login-form-social-btn__text {
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 24px;
          color: #344054;
        }
      }
    }

    .t-form {
      position: relative;

      .login-coming-soon {
        rotate: -45deg;
        font-weight: 400;
        font-size: 48px;
        line-height: 24px;
        text-align: center;
        color: #667085;
        margin-top: 12px;
        position: absolute;
        z-index: 99;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }


}
