@import "src/assets/styles/common";

$success-bg-color: #D3FFDD;
$success-color: #029422;
$info-bg-color: #E0F6FF;
$info-color: #59ABCD;
$warning-bg-color: #FDF6EB;
$warning-color: #FF5630;
$primary-bg-color: #EBF1FD;
$primary-color: #0478F0;
$default-bg-color: #E0F6FF;
$default-color: #636363;
$danger-bg-color: #FDEBEB;
$danger-color: #CE0202;

.tag-component {
  display: inline-flex;
  align-items: center;
  padding: 2px 20px;
  border-radius: 16px;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  font-family: $font-family;
  background: $default-bg-color;
  color: $default-color;

  &.success {
    @include tag-style($success-bg-color, $success-color);
  }

  &.info {
    @include tag-style($info-bg-color, $info-color);
  }

  &.warning {
    @include tag-style($warning-bg-color, $warning-color);
  }

  &.primary {
    @include tag-style($primary-bg-color, $primary-color);
  }

  &.default {
    @include tag-style($default-bg-color, $default-color);
  }

  &.danger {
    @include tag-style($danger-bg-color, $danger-color);
  }

  .indicator {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    margin-right: 5px;
    background-color: $default-color;

    &.success {
      background-color: $success-color;
    }

    &.info {
      background-color: $info-color;
    }

    &.warning {
      background-color: $warning-color;
    }

    &.primary {
      background-color: $primary-color;
    }

    &.default {
      background-color: $default-color;
    }

    &.danger {
      background-color: $danger-color;
    }

  }

  .label {
    line-height: 18px;
  }

}

