@import "src/assets/styles/common";

.link-component {
  font-weight: $font-weight-medium;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.002em;
  color: $secondary-color;
  display: flex;
  align-items: center;

  &:not(&:has(button)) {
    color: #2AB7A3 !important;
  }

  &:hover {
    color: #2AB7A3;
    cursor: pointer;
  }


  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}
