@import "src/assets/styles/common";

.columns-filter {
  .checkbox-component {
    margin-right: 10px;
  }
}

.dashboard-container {
  .dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .dashboard-left {
      @include display-center();
    }

    .dashboard-right {
      @include display-center();
    }

    .input-component {
      width: 260px;
      margin-bottom: 0;
    }

    .checkbox-component {
      margin-bottom: 0;
      margin-left: 10px;
    }
  }

  .table-container {
    height: calc(100vh - 170px);
    width: 100%;
    position: relative;
  }

  .loading-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }

  &:has(.no-data-card) {
    .table-container, .wtHolder, .handsontable {
      height: auto !important;
    }
  }

  .no-data-card {
    height: 400px;
    width: 100%;
    background-color: #fff;
    border-radius: 5px;
    //box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    //padding: 20px;
    font-size: 22px;
    @include display-center();
    flex-direction: column;
    color: #1F3864;
  }
}

.count-wrapper {
  display: flex;
  align-items: center;

  .label-component {
    margin-bottom: 0;
  }
}

.filter-block {
  display: flex;
  align-items: center;

  .filter-title {
    font-weight: 600;
  }
}
