.data-label-value {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;

  .data-label {
    font-weight: 400;
    letter-spacing: 0.5px;
    color: #07213C;
  }

  .data-value {
    line-height: 19px;
    align-items: center;
    color: #83909D;
  }

  &.row {
    flex-direction: row;
    align-items: center;
    .data-label {
      margin-left: 6px;
    }
    &:not(:first-child) {
      .data-label {
        margin-left: 0;
      }
    }
  }

  &.column {
    flex-direction: column;

    .data-label {
      margin-bottom: 6px;
    }
  }

}
