@import "src/assets/styles/common";

.branding-component {
  width: 107px;
  height: 36px;

  img {
    height: 100%;
    width: 100%;
  }

  font-size: 22px;
  font-weight: 500;
  color: #ffffff;

  align-items: center;
  display: flex;

  .li {
    color: #2eb6a3;
  }
}
