@import "assets/styles/common";

html, body{
  font-size: 14px;
  font-family: $font-family, sans-serif;
}

body {
  margin: 0;
  font-family: $font-family, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $body-color;
  background: $body-background-color;
}
