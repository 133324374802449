@import "src/assets/styles/common";

.t-table-wrapper {
  width: 100%;
  height: 100%;
  position: relative;

  .t-table {
    width: 100%;
    height: 100%;
    //border-radius: 8px;
    border: 1px solid #EBEBEB !important;
    min-height: 200px;

    .input-component {
      margin-bottom: 0 !important;
    }

    .rdg-header-row {
      color: #343434;

      .rdg-cell {
        font-weight: 500;
        font-size: 14px;
        color: #656362;
        background: #F9FAFB;
      }
    }

    .rdg-row {
      color: #0D0D0D;
    }

    .rdg-cell {
      border-inline-end: none !important;
      border-block-end: 1px solid #EBEBEB !important;
      color: #232323;
    }

    .custom-cell {
      line-height: normal;
      display: flex;
      align-items: center;
    }

    .rdg-cell[aria-selected='true'] {
      outline: none !important;
    }
  }

  .t-table__loader, .t-table__error-card, .t-table__no-data-card {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}


