@import "src/assets/styles/common";

$icon-button-size: 32px;

.icon-button {
  cursor: pointer;
  padding: 4px;
  height: $icon-button-size;
  width: $icon-button-size;
  border-radius: 50%;
  color: #1F3864;

  &:hover {
    //background: #eaeaea;
  }
}
