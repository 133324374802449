@import "src/assets/styles/common";

.design-system-screen {
  height: 100vh;
  width: 100vw;
  @include display-center();

  .design-system-form-container {
    width: 433px;

    .design-system-form {
      margin: 20px 0;
    }
  }

}
