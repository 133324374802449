@import "src/assets/styles/common";

$helper-text-font-family: $font-family;
$helper-text-font-size: 14px;
$helper-text-default-color: #667085;

.helper-text-component {
  font-family: $helper-text-font-family, sans-serif;
  font-size: $helper-text-font-size;
  line-height: 20px;
  color: $helper-text-default-color;

  &.error {
    color: $error-color;
  }

  &.success {
    color: $success-color;
  }

  &.warning {
    color: $warning-color;
  }

  &.info {
    color: $info-color;
  }

  &.default {
    color: $helper-text-default-color;
  }
}
